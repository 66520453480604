import { useEffect, useState } from 'react';
import { Button, Col, Form, ListGroup, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../../utils/query';
import ImageWrapper from '../../utils/image-wrapper';

import './styles.scss';
import Constant from '../../../Constant';
import KahootInfoList from '../kahoot-info-list';

const KahootsModal = ({courseID, hideModal}) => {
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [mainKeyword, setMainKeyword] = useState(null);
    const [editingCourse, setEditingCourse] = useState(false);

    useEffect(() => {
        const fetchKahoots = async() => {
            setLoading(true);
            const res = await getDataFromAPI('external-products/kahoot-courses/' + courseID + '/?additionalFields=kahoots,nbQuestions');
            setCourse(res.data);
            setMainKeyword(res.data['mainKeyword']);
            setLoading(false);
        }

        fetchKahoots();
    }, []);

    const createDataSetFromCourse = async() => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'external-products/create-data-set-from-course/?courseID=' + courseID);
        setCourse(prev => ({...prev, kahootID: res.data['kahootID']}));
        setLoading(false);
    }

    const createDataSetFromKahoot = async(kahoot) => {
        setLoading(true);
        const res = await updateDataAPI('POST', 'external-products/create-data-set-from-kahoot/?kahootID=' + kahoot.id);
        setCourse(prev => ({...prev, kahoots: prev.kahoots.map(k => {
            if (k.id === kahoot.id) {
                return {...k, kahootID: res.data['kahootID']};
            }
            return k;
        })}));
        hideModal('refresh');
        setLoading(false);
    }

    const disableCourse = async() => {
        setLoading(true);
        await updateDataAPI('PATCH', 'external-products/kahoot-courses/' + courseID + '/', {active: false});
        hideModal('disable');
        setLoading();
    }

    const updateCourse = async(field, value) => {
        setLoading(true);
        const res = await updateDataAPI('PATCH', 'external-products/kahoot-courses/' + courseID + '/?additionalFields=kahoots,nbQuestions', {[field]: value});
        setCourse(res.data);
        setLoading();
    }

    const updateKahoot = async(kahoot, field, value) => {
        setLoading(true);
        const res = await updateDataAPI('PATCH', 'external-products/kahoots/' + kahoot.id + '/', {[field]: value});
        setCourse({...course, kahoots: course.kahoots.map(k => {
            if (k.id === kahoot.id) {
                return res.data;
            }

            return k;
        })});
        setLoading();
    }

    return (
        <Modal show={true} onHide={hideModal} className="kahoots-modal" fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>Course {course?.id}. {course?.title} - Grades {course?.grades}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {course !== null && (
                    <Row className="course-info">
                        <Col>
                            <ListGroup>
                                <ListGroup.Item>
                                    <span>Total Questions</span>
                                    <span>{course.nbQuestions}</span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <span>Main Keyword</span>
                                    {editingCourse ? (
                                        <div>
                                            <Form.Control
                                                value={mainKeyword}
                                                onChange={e => setMainKeyword(e.target.value)}
                                                as="textarea"
                                                rows={3} />
                                            <Button size="sm" onClick={() => {
                                                updateCourse('mainKeyword', mainKeyword);
                                                setMainKeyword(null);
                                                setEditingCourse(false);
                                            }}>Save</Button>
                                        </div>
                                    ) : (
                                        <span onClick={() => {
                                            setEditingCourse(true);
                                            setMainKeyword(course.mainKeyword);
                                        }}>{course.mainKeyword}</span>
                                    )}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <span>Sub Keywords</span>
                                    <span>{course.subKeywords ? course.subKeywords.join(', ') : ''}</span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <span>Grades</span>
                                    <span>{course.grades}</span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <span>Category</span>
                                    <span>{course.category}</span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <span>Boom Subjects</span>
                                    <span>{course.boomSubjects ? course.boomSubjects.join(', ') : ''}</span>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col>
                            <div className="btns">
                                <a href={Constant.API_URL + 'admin/external_products/kahootcourse/' + courseID} target="_blank">Edit Course</a>
                                {course.keywordID !== null ? (
                                    <>
                                        <a href={Constant.STUDIO_URL + 'product-map/?keyword=' + course.keywordID} target="_blank">
                                            Keyword: {course.keywordID}
                                        </a>
                                        <Button onClick={() => updateCourse('keywordID', null)} variant="danger">Clear Keyword</Button>
                                    </>
                                ) : (
                                    <Button onClick={createDataSetFromCourse}>Create DataSet from Course</Button>
                                )}
                                <Button variant="danger" onClick={disableCourse}>Disable Course</Button>
                            </div>
                        </Col>
                    </Row>
                )}
                {course !== null && (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Title</th>
                                <th>Info</th>
                                <th>Nb Questions</th>
                                <th>Questions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {course.kahoots.map((kahoot, index) => (
                                <tr key={kahoot.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <a href={'https://create.kahoot.it/details/' + kahoot.uid} target="_blank" rel="noreferrer">
                                            {kahoot.title}
                                        </a>
                                    </td>
                                    <td>
                                        <KahootInfoList
                                            kahoot={kahoot}
                                            setKahoot={data => {
                                                setCourse({...course, kahoots: course.kahoots.map(k => {
                                                    if (k.id === data.id) {
                                                        return data;
                                                    }

                                                    return k;
                                                })});
                                            }}
                                            setLoading={setLoading}
                                        />

                                        {kahoot.keywordID !== null ? (
                                            <div>
                                                <a href={Constant.STUDIO_URL + 'product-map/?keyword=' + kahoot.keywordID} target="_blank">
                                                    Keyword: {kahoot.keywordID}
                                                </a>
                                                <Button size="sm" variant="danger" onClick={() => updateKahoot(kahoot, 'keywordID', null)}>Clear Keyword</Button>
                                            </div>
                                        ) : (
                                            <Button onClick={() => createDataSetFromKahoot(kahoot)}>Create DataSet</Button>
                                        )}
                                    </td>
                                    <td>{kahoot.nbQuestions}</td>
                                    <td>
                                        <ListGroup>
                                            {kahoot.content.filter(question => question.type === 'quiz' || question.type === 'open_ended').slice(0, 2).map((question, index) => (
                                                <ListGroup.Item key={index}>
                                                    <div className="question">
                                                        <div dangerouslySetInnerHTML={{ __html: question['question'] }}></div>
                                                        <div className="question-choices">
                                                            {question['choices'].map((choice, choiceIndex) => (
                                                                <span className={choice['correct'] ? 'correct-choice' : ''} key={choiceIndex}>
                                                                    {choice['image'] ? (
                                                                        <ImageWrapper src={'https://media.kahoot.it/' + choice['image']['id']} />
                                                                    ) : (
                                                                        <span>{choice['answer']}</span>
                                                                    )}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {question['image'] && (
                                                            <ImageWrapper src={question['image']} />
                                                        )}
                                                    </div>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Modal.Body>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    )
}

export default KahootsModal;