import { useEffect, useState } from 'react';
import { Container, Form, ListGroup, Spinner, Table } from 'react-bootstrap';
import { getDataFromAPI } from '../../utils/query';

import './styles.scss';
import KahootsModal from '../../components/kahoots/kahoots-modal';
import Constant from '../../Constant';

const KahootsPage = () => {
    const [creators, setCreators] = useState([]);
    const [courseInModal, setCourseInModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filteredCourseLinkedToKeyword, setFilteredCourseLinkedToKeyword] = useState('all');

    useEffect(() => {
        const fetchCreators = async() => {
            setLoading(true);
            const res = await getDataFromAPI('external-products/kahoot-creators/?additionalFields=courses');
            setCreators(res.data);
            setLoading(false);
        }

        fetchCreators();
    }, []);

    const refetchCourse = async(creatorID, courseID) => {
        setLoading(true);
        console.log(creatorID, courseID);
        const res = await getDataFromAPI('external-products/kahoot-courses/' + courseID + '?additionalFields=kahoots,nbQuestions');
        setCreators(prev => prev.map(creator => {
            if (creator.id === creatorID) {
                return {...creator, courses: creator.courses.map(c => {
                    if (c.id === courseID) {
                        return res.data;
                    }
                    return c;
                })}
            }
            return creator;
        }));
        setLoading(false);
    }

    return (
        <Container className="kahoots-page">
            <div className="filters">
                <Form.Select value={filteredCourseLinkedToKeyword} onChange={e => setFilteredCourseLinkedToKeyword(e.target.value)}>
                    <option value="all">all</option>
                    <option value="yes">linked to keyword</option>
                    <option value="no">unlink</option>
                </Form.Select>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Creator</th>
                        <th>Courses</th>
                    </tr>
                </thead>
                <tbody>
                    {creators.map(creator => (
                        <tr key={creator.id}>
                            <td>
                                <div>
                                    {creator.name}
                                </div>
                                <div>
                                    {creator.courses.length} courses
                                </div>
                            </td>
                            <td>
                                <ListGroup>
                                    {creator.courses.filter(course => {
                                        if (filteredCourseLinkedToKeyword !== 'all' && ((filteredCourseLinkedToKeyword === 'yes' && course.keywordID === null) || (filteredCourseLinkedToKeyword === 'no' && course.keywordID !== null))) {
                                            return false;
                                        }

                                        if (course.nbQuestions === 0) {
                                            return false;
                                        }

                                        return true;
                                    }).map(course => (
                                        <ListGroup.Item key={course.id}>
                                            <div>
                                                <div>
                                                    <a href={'https://create.kahoot.it/course/' + course.uid} target="_blank" rel="noreferrer"
                                                        className={(course.mainKeyword !== null && course.boomSubjects !== null && course.category !== null) ? 'text-success' : 'text-danger'}>
                                                        {course.title}
                                                    </a>
                                                </div>
                                                <div>Grades: {course.grades}</div>
                                            </div>
                                            <div className="nb-questions">
                                                <div onClick={() => setCourseInModal(course)}>{course.nbQuestions} questions</div>
                                                {course.keywordID && (
                                                    <div>
                                                        <a href={Constant.STUDIO_URL + 'product-map/?keyword=' + course.keywordID} target="_blank">
                                                            Keyword: {course.keywordID}
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {courseInModal !== null && (
                <KahootsModal
                    courseID={courseInModal.id}
                    hideModal={action => {
                        if (action === 'refresh') {
                            refetchCourse(courseInModal.creator, courseInModal.id);
                        } else if (action === 'disable') {
                            setCreators(prev => prev.map(creator => {
                                if (creator.id === courseInModal.creator) {
                                    return {...creator, courses: creator.courses.filter(c => c.id !== courseInModal.id)}
                                }
                                return creator;
                            }));
                        }
                        setCourseInModal(null);
                    }}
                />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default KahootsPage;