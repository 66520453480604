import { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { getDataFromAPI } from '../../../utils/query';
import { LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';

import './styles.scss';

const KeywordHistoryChartModal = ({ keywordID, hideModal }) => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState(null);

    useEffect(() => {
        fetchKeywordHistory();
    }, []);

    const fetchKeywordHistory = async() => {
        const res = await getDataFromAPI('keywords/get-keyword-popularity-history/?keywordID=' + keywordID);
        setHistory(res.data['history']);
        setKeyword(res.data['keyword'])
        setLoading(false);
    }

    return (
        <Modal show={true} onHide={hideModal} size="xl" className="keyword-history-chart-modal">
            <Modal.Header closeButton>
                <Modal.Title>{keyword?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {history.length > 0 && (
                    <ResponsiveContainer width="100%" height={500}>
                        <LineChart width="100%" height={300} data={history}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
                            <YAxis yAxisId="left" />
                            <Tooltip />
                            <Line yAxisId="left" dataKey="popularity" stroke="#dc3545" activeDot={{ r: 5 }} />
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </Modal.Body>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    )
}

export default KeywordHistoryChartModal;