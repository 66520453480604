import { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Col,
    Container,
    Form,
    InputGroup,
    ListGroup,
    Modal, Row,
    Spinner,
    Table
} from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faClose, faChevronUp, faChevronDown, faChartArea, faList } from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import FilterByDateRange from '../../components/filters/datepicker';
import moment from 'moment';

import './styles.scss';
import { useSelector } from 'react-redux';
import Constant from '../../Constant';
import KeywordResearchPagination from '../../components/keyword-research/pagination';
import KeywordHistoryChartModal from '../../components/keyword-research/keyword-history-chart-modal';
import KeywordIdeasListModal from '../../components/keyword-research/keyword-ideas-list-modal';

const KeywordResearchPage = ({ }) => {
    const startDate = useSelector((state) => state.filters.startDate);
    const endDate = useSelector((state) => state.filters.endDate);

    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState('');
    const [submitedQuery, setSubmitedQuery] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [keywordSuggestions, setKeywordSuggestions] = useState([]);
    const [result, setResult] = useState([]);
    const [minPopularity, setMinPopularity] = useState(null);
    const [maxPopularity, setMaxPopularity] = useState(null);
    const [sortingColumn, setSortingColumn] = useState('popularity');
    const [sortingDirection, setSortingDirection] = useState('down');
    const [showRelatedProductsColumn, setShowRelatedProductsColumn] = useState(false);
    const [selectedDifficulty, setSelectedDifficulty] = useState('all');
    const [newKeyword, setNewKeyword] = useState();
    const [productRankings, setProductRankings] = useState({});
    const [productSales, setProductSales] = useState({});
    const [nbPages, setNbPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [productInModal, setProductInModal] = useState(null);
    const [keywordIDInHistoryChartModal, setKeywordIDInHistoryChartModal] = useState(null);
    const [keywordInIdeasListModal, setKeywordInIdeasListModal] = useState(null);

    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        setUserRole(localStorage.getItem(Constant.LOCAL_STORAGE_ROLE));
    }, []);

    useEffect(() => {
        fetchKeywordsByQuery();
    }, [currentPage]);

    // useEffect(() => {
    //     if (query) {
    //         fetchKeywordsByQuery();
    //     }
    // }, [sortingColumn, sortingDirection]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (query) {
                getKeywordSuggestions();
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    const getKeywordSuggestions = async () => {
        const res = await getDataFromAPI('keywords/suggest-keywords/?query=' + query);
        setKeywordSuggestions(res.data);
    }

    const fetchKeywordsByQuery = async () => {
        setLoading(true);
        console.log('search');
        let res, keywords;

        let url = 'keywords/fetch-keywords/?page=' + (currentPage - 1);

        if (startDate !== null) {
            url += '&startDate=' + startDate;
        } else {
            url += '&startDate=' + moment().startOf('month').format('YYYY-MM-DD');
        }

        if (endDate !== null) {
            url += '&endDate=' + endDate;
        } else {
            url += '&endDate=' + moment().endOf('month').format('YYYY-MM-DD');
        }

        if (query) {
            url += '&query=' + query;
            // if (selectedDifficulty !== 'all') {
            //     url += '&difficulty=' + selectedDifficulty;
            // }
            // url += '&orderBy=' + sortingColumn;
            // url += '&orderDir=' + sortingDirection;
        }

        if (minPopularity !== null && minPopularity !== '' && minPopularity >= 0) {
            url += '&minPopularity=' + minPopularity;
        }
        if (maxPopularity !== null && maxPopularity !== '' && maxPopularity >= 0) {
            url += '&maxPopularity=' + maxPopularity;
        }

        res = await getDataFromAPI(url);
        setNbPages(Math.ceil(res.data['count'] / 100));
        keywords = res.data['results'];

        const relatedProductIDs = [];
        const relatedProductTPTIDs = [];
        for (let i = 0; i < keywords.length; i++) {
            const relatedProducts = keywords[i].relatedProducts;
            if (relatedProducts) {
                for (let j = 0; j < relatedProducts.length; j++) {
                    if (relatedProductIDs.indexOf(relatedProducts[j].id) === -1) {
                        relatedProductIDs.push(relatedProducts[j].id);
                    }
                    if (relatedProductTPTIDs.indexOf(relatedProducts[j].tptID) === -1) {
                        relatedProductTPTIDs.push(relatedProducts[j].tptID);
                    }
                }
            }
        }

        await getProductKeywordRanks(relatedProductIDs);
        await getProductSales(relatedProductTPTIDs);

        console.log('>', keywords);
        setResult(keywords);
        setLoading(false);
    }

    const getProductKeywordRanks = async (relatedProductIDs) => {
        const res = await updateDataAPI('POST', 'rankchecker/get-product-keyword-ranks/', {
            ids: relatedProductIDs
        });
        setProductRankings(res.data);
    }

    const getProductSales = async (relatedProductTPTIDs) => {
        if (startDate !== null && endDate !== null) {
            const res = await updateDataAPI('POST', 'rankchecker/get-sales-by-tpt-ids/?startDate=' + startDate + '&endDate=' + endDate, {
                tptIDs: relatedProductTPTIDs
            });
            setProductSales(res.data);
        }
    }

    const addNewKeyword = async (e) => {
        e.preventDefault();
        if (newKeyword.length > 0) {
            try {
                await updateDataAPI('POST', 'keywords/keywords/', {
                    name: newKeyword
                });
                alert('thêm keyword thành công');
                setNewKeyword('');
            } catch (e) {
                alert('keyword đã tồn tại');
            }
        }
    }

    const formatProductRank = rank => {
        if (rank < 24) {
            return (
                <Badge bg="success" className="badge-rank">
                    <div>Page 1</div>
                    <div>Pos {rank}</div>
                </Badge>
            );
        }
        return (
            <Badge bg="info" className="badge-rank">
                <div>Page 2</div>
                <div>Pos {rank - 24}</div>
            </Badge>
        )
    }

    const updateKeyword = async (keyword, field) => {
        setLoading(true);
        await updateDataAPI('PATCH', 'keywords/keywords/' + keyword.keyword + '/', {
            [field]: !keyword[field]
        });
        setResult(result.map(k => {
            if (k.id !== keyword.id) {
                return k;
            }
            return { ...k, [field]: !k[field] };
        }));
        setLoading(false);
    }

    return (
        <Container className="keyword-research-container" fluid>
            <div className="header">
                <div className="filters-left">
                    <Form>
                        <Row>
                            <Col>
                                <div className="keyword-search-input">
                                    <Form.Control value={query} onChange={e => setQuery(e.target.value)} placeholder="search keyword"
                                        onFocus={() => setShowSuggestions(true)} />
                                    {showSuggestions ? (
                                        <FontAwesomeIcon icon={faChevronDown} className="suggestion-icon suggestion-show-icon" onClick={() => setShowSuggestions(false)} />
                                    ) : (
                                        <FontAwesomeIcon icon={faChevronUp} className="suggestion-icon suggestion-hide-icon" onClick={() => setShowSuggestions(true)} />
                                    )}
                                    <FontAwesomeIcon icon={faClose} className="delete-icon" onClick={() => setQuery('')} />
                                    {keywordSuggestions.length > 0 && query !== submitedQuery && showSuggestions && (
                                        <div className="keyword-suggestions">
                                            {keywordSuggestions.map((keyword, index) => (
                                                <div key={index} onClick={() => {
                                                    setQuery(keyword);
                                                    setSubmitedQuery(keyword);
                                                    setKeywordSuggestions([]);
                                                }}>{keyword}</div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col>
                                <FilterByDateRange
                                    defaultDateRange={{
                                        startDate: moment().startOf('month').toDate(),
                                        endDate: moment().endOf('month').toDate(),
                                    }}
                                />
                            </Col>
                            <Col md="2">
                                <Button onClick={e => {
                                    e.preventDefault();
                                    setSubmitedQuery(query.toLowerCase());
                                    fetchKeywordsByQuery();
                                }}>Filter</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {submitedQuery !== null && (
                                    <div className="mt-2 filter-by-popularity">
                                        <label>Popularity</label>
                                        <Row>
                                            <Col>
                                                <Form.Control value={minPopularity} onChange={e => setMinPopularity(e.target.value)} placeholder="min" />
                                            </Col>
                                            <Col>
                                                <Form.Control value={maxPopularity} onChange={e => setMaxPopularity(e.target.value)} placeholder="max" />
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Col>
                            <Col>
                                {userRole === 'admin' && (
                                    <div className="mt-2">
                                        <label>Show Related Products</label>
                                        <select className="form-select" value={showRelatedProductsColumn} onChange={(e => setShowRelatedProductsColumn(e.target.value))}>
                                            <option value={true}>Yes</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        {/* <div>
                            <label>Difficulty</label>
                            <select className="form-select" onChange={e => setSelectedDifficulty(e.target.value)}>
                                <option value="all">All</option>
                                <option value="easy">Easy</option>
                                <option value="medium">Medium</option>
                                <option value="hard">Hard</option>
                            </select>
                        </div> */}
                    </Form>
                </div>
                <div className="filters-right">
                    {userRole === 'admin' && (
                        <Form onSubmit={addNewKeyword} className="add-new-keyword-form">
                            <InputGroup className="mb-3">
                                <Form.Control value={newKeyword} onChange={e => setNewKeyword(e.target.value.toLowerCase())} placeholder="new keyword" />
                                <Button type="submit" size="sm">Add new keyword</Button>
                            </InputGroup>
                        </Form>
                    )}
                </div>
            </div>

            {nbPages > 0 && (
                <KeywordResearchPagination currentPage={currentPage} setCurrentPage={setCurrentPage} nbPages={nbPages} />
            )}

            {result.length > 0 ? (
                <Table className="result" striped hover bordered>
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <span>Name</span>
                                {/* {sortingColumn === 'score' && sortingDirection === 'asc' && (
                                    <FontAwesomeIcon icon={faChevronCircleUp} />
                                )}
                                {sortingColumn === 'score' && sortingDirection === 'desc' && (
                                    <FontAwesomeIcon icon={faChevronCircleDown} />
                                )} */}
                            </th>
                            <th>
                                {/* onClick={() => updateSorting('popularity')} */}
                                <span>Popularity</span>
                                {/* {sortingColumn === 'popularity' && sortingDirection === 'asc' && (
                                    <FontAwesomeIcon icon={faChevronCircleUp} />
                                )}
                                {sortingColumn === 'popularity' && sortingDirection === 'desc' && (
                                    <FontAwesomeIcon icon={faChevronCircleDown} />
                                )} */}
                            </th>
                            {userRole === 'admin' && (
                                <th>
                                    <span>Difficulty</span>
                                    {/* {sortingColumn === 'difficulty' && sortingDirection === 'asc' && (
                                        <FontAwesomeIcon icon={faChevronCircleUp} />
                                    )}
                                    {sortingColumn === 'difficulty' && sortingDirection === 'desc' && (
                                        <FontAwesomeIcon icon={faChevronCircleDown} />
                                    )} */}
                                </th>
                            )}
                            {showRelatedProductsColumn && userRole === 'admin' && (
                                <th>
                                    Related Products
                                </th>
                            )}
                            <th>To Check</th>
                            <th>Check Done</th>
                        </tr>
                    </thead>
                    <tbody>
                        {result.map((keyword, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="keyword-cell">
                                    <div>
                                        <Highlighter
                                            highlightClassName="YourHighlightClass"
                                            searchWords={submitedQuery.toLowerCase().trim().split(' ')}
                                            autoEscape={true}
                                            textToHighlight={keyword.name}
                                        />
                                        <div className="meta-links">
                                            <a href={'https://www.teacherspayteachers.com/Browse/Search:' + keyword.name} target="_blank">
                                                <Badge bg="secondary" size="sm">TPT</Badge>
                                            </a>
                                            <a href={'https://www.teacherspayteachers.com/Browse/Search:' + keyword.name + '/on-sale'} target="_blank">
                                                <Badge bg="danger" size="sm">ON SALE</Badge>
                                            </a>
                                            <a href={'https://www.teacherspayteachers.com/Browse/Search:' + keyword.name + '/free'} target="_blank">
                                                <Badge bg="warning" size="sm">FREE</Badge>
                                            </a>
                                            <a href={'/tpt-search-result?keyword=' + keyword.name} target="_blank">
                                                <FontAwesomeIcon icon={faSearch} />
                                            </a>
                                            <FontAwesomeIcon icon={faList} onClick={() => setKeywordInIdeasListModal(keyword)} />
                                            <span onClick={() => setKeywordIDInHistoryChartModal(keyword.keyword)}><FontAwesomeIcon icon={faChartArea} /></span>
                                        </div>
                                    </div>
                                    {keyword.productExamples && keyword.productExamples.length > 0 && (
                                        <div className="product-examples">
                                            {keyword.productExamples.map((product, index) => (
                                                <div className={'product-thumbnails ' + (index > 1 ? 'extra' : '')} key={index}
                                                    onClick={() => setProductInModal(product)}>
                                                    <img src={product.thumbnailUrl} />
                                                    <img src={product.preview1Url} />
                                                    <img src={product.preview2Url} />
                                                    <img src={product.preview3Url} />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                <td>{keyword.popularity > -1 ? keyword.popularity : ''}</td>
                                {userRole === 'admin' && (
                                    <td>
                                        <span className={'diff-level diff-level-' + keyword.difficulty}>{keyword.difficulty}</span>
                                    </td>
                                )}
                                {showRelatedProductsColumn && userRole === 'admin' && (
                                    <td>
                                        {keyword.relatedProducts && (
                                            <ListGroup>
                                                {keyword.relatedProducts.map(product => (
                                                    <ListGroup.Item key={product.id} className="d-flex justify-content-between">
                                                        <div className="product-preview">
                                                            {product.thumbnail && <img src={product.thumbnail} />}
                                                            <div>
                                                                <a href={product.url} target="_blank">
                                                                    <Highlighter
                                                                        highlightClassName="YourHighlightClass"
                                                                        searchWords={[keyword.name]}
                                                                        autoEscape={true}
                                                                        textToHighlight={product.title}
                                                                    />
                                                                </a>
                                                                <div>{product.price ? ('PAID: $' + product.price) : 'FREE'}</div>
                                                                <div>{product.nbRatings} rates</div>
                                                                <div>TPTID: {product.tptID}</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {productRankings[product.id] && productRankings[product.id][keyword.id] && (
                                                                formatProductRank(productRankings[product.id][keyword.id])
                                                            )}
                                                            {productSales[product.tptID] > 0 && (
                                                                <Badge bg="danger">
                                                                    {productSales[product.tptID]} sales
                                                                </Badge>
                                                            )}
                                                        </div>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        )}
                                    </td>
                                )}
                                <td>
                                    <Form.Check type="checkbox" checked={keyword.toCheck} onChange={() => updateKeyword(keyword, 'toCheck')}
                                        disabled={userRole !== 'admin'} />
                                </td>
                                <td>
                                    <Form.Check type="checkbox" checked={keyword.checkDone} onChange={() => updateKeyword(keyword, 'checkDone')}
                                        disabled={!keyword.toCheck} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <div>No result</div>
            )}

            {nbPages > 0 && (
                <KeywordResearchPagination currentPage={currentPage} setCurrentPage={setCurrentPage} nbPages={nbPages} />
            )}

            {productInModal !== null && (
                <Modal show={true} onHide={() => setProductInModal(null)} size="xl" className="keyword-product-examples-modal">
                    <Modal.Body>
                        <img src={productInModal.thumbnailUrl} />
                        <img src={productInModal.preview1Url} />
                        <img src={productInModal.preview2Url} />
                        <img src={productInModal.preview3Url} />
                    </Modal.Body>
                </Modal>
            )}

            {keywordIDInHistoryChartModal !== null && (
                <KeywordHistoryChartModal keywordID={keywordIDInHistoryChartModal} hideModal={() => setKeywordIDInHistoryChartModal(null)} />
            )}

            {keywordInIdeasListModal !== null && (
                <KeywordIdeasListModal keyword={keywordInIdeasListModal} hideModal={() => setKeywordInIdeasListModal(null)} />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default KeywordResearchPage;