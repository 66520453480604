import { useState } from 'react';
import { Button, Form, ListGroup } from 'react-bootstrap';
import { updateDataAPI } from '../../../utils/query';

const KahootInfoList = ({kahoot, setKahoot, setLoading}) => {
    const [mainKeyword, setMainKeyword] = useState(null);
    const [editing, setEditing] = useState(false);

    const updateKahoot = async(field, value) => {
        setLoading(true);
        const res = await updateDataAPI('PATCH', 'external-products/kahoots/' + kahoot.id + '/', {[field]: value});
        setKahoot(res.data);
        setLoading();
    }

    return (
        <ListGroup>
            <ListGroup.Item>
                <span>Main Keyword</span>
                {editing ? (
                    <div>
                        <Form.Control
                            value={mainKeyword}
                            onChange={e => setMainKeyword(e.target.value)}
                            as="textarea"
                            rows={3} />
                        <Button size="sm" onClick={() => {
                            updateKahoot('mainKeyword', mainKeyword);
                            setMainKeyword(null);
                            setEditing(false);
                        }}>Save</Button>
                    </div>
                ) : (
                    <span onClick={() => {
                        setEditing(true);
                        setMainKeyword(kahoot.mainKeyword);
                    }}>{kahoot.mainKeyword}</span>
                )}
            </ListGroup.Item>
            <ListGroup.Item>
                <span>Sub Keywords</span>
                <span>{kahoot.subKeywords ? kahoot.subKeywords.join(', ') : ''}</span>
            </ListGroup.Item>
            <ListGroup.Item>
                <span>Grades</span>
                <span>{kahoot.grades ? kahoot.grades.join(', ') : ''}</span>
            </ListGroup.Item>
            <ListGroup.Item>
                <span>Category</span>
                <span>{kahoot.category}</span>
            </ListGroup.Item>
            <ListGroup.Item>
                <span>Boom Subjects</span>
                <span>{kahoot.boomSubjects ? kahoot.boomSubjects.join(', ') : ''}</span>
            </ListGroup.Item>
        </ListGroup>
    );
}

export default KahootInfoList;