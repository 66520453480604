import { Container, Form } from 'react-bootstrap';
import FilterByStore from '../../components/filters/store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getDataFromAPI } from '../../utils/query';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import randomColor from 'randomcolor';

import './styles.scss';
import FilterByDateRange from '../../components/filters/datepicker';
import moment from 'moment';

const StoreRankHistoryPage = () => {
    const [storeIndicators, setStoreIndicators] = useState([]);
    const [allStoreRanks, setAllStoreRanks] = useState([]);
    const [activeStores, setActiveStores] = useState({});
    const [storeColors, setStoreColors] = useState({});
    const [storeStrokes, setStoreStrokes] = useState({});

    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const startDate = useSelector((state) => state.filters.startDate);
    const endDate = useSelector((state) => state.filters.endDate);

    useEffect(() => {
        if (selectedStoreID !== null) {
            fetchStoreIndicators();
        } else {
            fetchAllStoreRanks();
        }
    }, [selectedStoreID, startDate, endDate]);

    const fetchStoreIndicators = async () => {
        const res = await getDataFromAPI('rankchecker/store-ranks/?store=' + selectedStoreID + '&startDate=' + startDate + '&endDate=' + endDate);

        setStoreIndicators(res.data);
    }

    const fetchAllStoreRanks = async () => {
        const res = await getDataFromAPI('rankchecker/store-ranks/?startDate=' + startDate + '&endDate=' + endDate);
        const allStoreRanks = [];
        const storeColors = {};
        const storeStrokes = {};
        const activeStores = {};
        for (let i = 0; i < res.data.length; i++) {
            const dateIndex = allStoreRanks.findIndex(s => s.date === res.data[i].date);
            const rank = res.data[i].rank;
            const storeName = res.data[i].store + '. ' + res.data[i].storeName;
            if (dateIndex > -1) {
                allStoreRanks[dateIndex][storeName] = rank;
            } else {
                allStoreRanks.push({
                    date: res.data[i].date,
                    [storeName]: rank,
                });
            }
            if (storeColors[storeName] === undefined) {
                storeColors[storeName] = randomColor({
                    luminosity: 'dark',
                });
                storeStrokes[storeName] = 1;
            }
            activeStores[storeName] = true;
        }
        setAllStoreRanks(allStoreRanks);
        setStoreColors(storeColors);
        setStoreStrokes(storeStrokes);
        setActiveStores(activeStores);
    }

    return (
        <Container className="store-rank-history">
            <div className="filters">
                <FilterByStore hasAllOption={true} />
                <FilterByDateRange
                    defaultDateRange={{
                        startDate: moment().subtract(2, 'months').startOf('month').toDate(),
                        endDate: moment().toDate(),
                    }}
                />
            </div>
            <div className="graph">
                {storeIndicators.length > 0 && selectedStoreID !== null && (
                    <ResponsiveContainer width="100%" height={500}>
                        <LineChart width="100%" height={300} data={storeIndicators}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
                            <YAxis yAxisId="left" />
                            <YAxis yAxisId="right" orientation="right" />
                            <Tooltip />
                            <Line yAxisId="left" dataKey="nbFollowers" stroke="#dc3545" activeDot={{ r: 5 }} />
                            <Line yAxisId="left" dataKey="nbRates" stroke="#0d6efd" activeDot={{ r: 5 }} />
                            <Line yAxisId="left" dataKey="nbActiveItems" stroke="#6c757d" activeDot={{ r: 5 }} />
                            <Line yAxisId="right" dataKey="rank" stroke="#198754" activeDot={{ r: 5 }} />
                        </LineChart>
                    </ResponsiveContainer>
                )}
                {allStoreRanks.length > 0 && selectedStoreID === null && (
                    <div>
                        <ResponsiveContainer width="100%" height={500}>
                            <LineChart width="100%" height={300} data={allStoreRanks}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
                                <YAxis />
                                <Tooltip />
                                {Object.keys(allStoreRanks[0]).filter(key => key !== 'color' && key !== 'date' && activeStores[key]).map(store => (
                                    <Line key={store} dataKey={store} stroke={storeColors[store]} activeDot={{ r: 5 }} strokeWidth={storeStrokes[store]} />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>

                        <Form className="toggle-stores-form">
                            <Form.Group controlId={'toggle-all-stores'}>
                                <Form.Check type="checkbox" label="Select All" checked={Object.values(activeStores).filter(bool => bool).length === Object.keys(activeStores).length}
                                    onChange={e => {
                                        const stores = Object.keys(activeStores);
                                        const obj = {};
                                        for (let i = 0; i < stores.length; i++) {
                                            obj[stores[i]] = true;
                                        }
                                        setActiveStores(obj);
                                    }} />
                            </Form.Group>
                            <Form.Group controlId={'toggle-none-stores'}>
                                <Form.Check type="checkbox" label="Deselect All" checked={Object.values(activeStores).filter(bool => bool).length === 0}
                                    onChange={e => {
                                        const stores = Object.keys(activeStores);
                                        const obj = {};
                                        for (let i = 0; i < stores.length; i++) {
                                            obj[stores[i]] = false;
                                        }
                                        console.log('deselect', obj);
                                        setActiveStores(obj);
                                    }} />
                            </Form.Group>
                            {Object.keys(activeStores).map((store, index) => (
                                <Form.Group controlId={'toggle-store-' + index} key={index}>
                                    <div onMouseEnter={() => setStoreStrokes({ ...storeStrokes, [store]: 3 })}
                                        onMouseLeave={() => setStoreStrokes({ ...storeStrokes, [store]: 1 })}>
                                        <Form.Check style={{ color: storeColors[store] }} type="checkbox" label={store}
                                            checked={activeStores[store]} onChange={e => setActiveStores({ ...activeStores, [store]: !activeStores[store] })}
                                        />
                                    </div>
                                </Form.Group>
                            ))}
                        </Form>
                    </div>
                )}
            </div>
        </Container>
    )
}

export default StoreRankHistoryPage;