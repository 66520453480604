import { useEffect, useState } from 'react';
import { Modal, Spinner, Table } from 'react-bootstrap';

import './styles.scss';
import { updateDataAPI } from '../../../utils/query';

const KeywordIdeasListModal = ({ keyword, hideModal }) => {
    const [ideas, setIdeas] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchIdeas();
    }, []);

    useEffect(() => {
        console.log(ideas);
    }, [ideas]);

    const fetchIdeas = async () => {
        setLoading(true);
        const url = 'keywords/get-all-ideas-using-keywords/';

        try {
            const res = await updateDataAPI('POST', url, { keywords: [keyword.name] });
            setIdeas(res.data[keyword.name]);
        } catch(err) {
            console.error('An error occured when getting ideas using keywords: ', err.message);
        }
        setLoading(false);
    }

    return (
        <Modal show={true} onHide={hideModal} size="xl" className="keyword-ideas-list-modal">
            <Modal.Header closeButton>
                <Modal.Title>{keyword?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table className="ranks" striped hover bordered>
                    <thead>
                        <tr>
                            <th></th>
                            <th>CustomID</th>
                            <th>Title</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ideas.map((idea, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{idea.customID}</td>
                                <td>{idea.title}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Modal>
    )
}

export default KeywordIdeasListModal;