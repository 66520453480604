export default class Constant {
    static API_URL = 'https://tptseller-api.k12worksheet.com/';
    static STUDIO_URL = 'https://resources.k12worksheet.com/';
    // static API_URL = 'http://localhost:8000/';

    static LOCAL_STORAGE_PREFIX = '_bee1203_tptseller_';
    static LOCAL_STORAGE_TOKEN = this.LOCAL_STORAGE_PREFIX + 'token';
    static LOCAL_STORAGE_ROLE = this.LOCAL_STORAGE_PREFIX + 'role';
    static LOCAL_STORAGE_USER_EMAIL = this.LOCAL_STORAGE_PREFIX + 'user_email';

    static ROUTE_PERMISSIONS = {
        '/': ['admin', 'tptseller', 'staff'],
        '/keyword-research': ['admin', 'staff'],
        '/check-store-rank': ['admin'],
        '/tpt-search-result': ['admin', 'staff'],
        '/store-rank-history': ['admin'],
        '/product-reviews': ['admin'],
        '/kahoots': ['admin'],
    };
    static ACTIVITY_PERMISSIONS = {
    };
}