import { Pagination } from "react-bootstrap";

const KeywordResearchPagination = ({ currentPage, setCurrentPage, nbPages }) => {
    return (
        <div className="pagination-container">
            <Pagination>
                {currentPage > 1 && (
                    <>
                        <Pagination.First onClick={() => setCurrentPage(1)} />
                        <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />
                    </>
                )}
                {[3, 2, 1].map(index => (
                    currentPage > index && (
                        <Pagination.Item onClick={() => setCurrentPage(currentPage - index)} key={'page-before-active-' + index}>
                            {currentPage - index}
                        </Pagination.Item>
                    )
                ))}
                <Pagination.Item active={true}>
                    {currentPage}
                </Pagination.Item>
                {[1, 2, 3].map(index => (
                    currentPage + index <= nbPages && (
                        <Pagination.Item onClick={() => setCurrentPage(currentPage + index)} key={'page-after-active-' + index}>
                            {currentPage + index}
                        </Pagination.Item>
                    )
                ))}
                {currentPage < nbPages && (
                    <>
                        <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />
                        <Pagination.Last onClick={() => setCurrentPage(nbPages)} />
                    </>
                )}
            </Pagination>
        </div>
    )
}

export default KeywordResearchPagination;